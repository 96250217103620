<template lang="html">
  <div class="" style="text-align: left;">
    <login></login>
    <!-- Page Contents -->
    <div class="ui menu show" style="border:0;margin:0;box-shadow:0 0 0 0;margin-left:auto;margin-right:auto;">
      <a href="/" @click="showHome" class="item" style="padding-top:0;padding-bottom:0;cursor:pointer;">
        <img class="ui image" src="../assets/images/Logo_Spext_1.png">
      </a>
      <div @click="menu=!menu" class="ui right item" style="padding-top:0;padding-bottom:0;">
        <i class="fa fa-bars"/>
      </div>
    </div>
    <div class="show1" v-show="menu" style="text-align:center;">
      <div class="item" style="padding-top:15px;">
        <a class="menuItem" href="https://intercom.help/spext/faqs" target="_blank" rel="noopener" @click="showFAQ">FAQs</a>
      </div>
      <div class="item" style="padding-top:15px;">
        <a class="menuItem" href="/security" @click="showSecurity">SECURITY</a>
      </div>
      <div class="item" style="padding-top:15px;">
        <a class="menuItem" href="/privacy" @click="showPrivacy">PRIVACY</a>
      </div>
      <div class="item" style="padding-top:15px;">
        <a class="menuItem"  href="https://guides.spext.co/" target="_blank" rel="noopener" @click="showGuide">GUIDES</a>
      </div>
      <!-- <div class="item" style="padding-top:15px;">
        <a class="menuItem" href="https://legal.spext.co/" target="_blank" rel="noopener" @click="showLegal">FOR LEGAL</a>
      </div> -->
      <div class="item" style="padding-top:15px;">
        <a class="menuItem" href="https://blog.spext.co/" target="_blank" rel="noopener" @click="showBlog">BLOG</a>
      </div>
    </div>
    <div class="ui stackable menu bottomMargin menuHeight hide" style="width:95%;border:0;box-shadow:0 0 0 0;margin-left:auto;margin-right:auto;">
      <a href="/" @click="showHome" class="ui item" style="padding-top:15px;cursor:pointer;">
        <img class="ui image" src="../assets/images/Logo_Spext_1.png">
      </a>
      <div class="ui stackable right menu" style="border:0;box-shadow:0 0 0 0">
        <div class="item" style="padding-top:15px;">
          <a class="menuItem" href="https://intercom.help/spext/faqs" target="_blank" rel="noopener" @click="showFAQ">FAQs</a>
        </div>
        <div class="item" style="padding-top:15px;">
          <a class="menuItem" href="/security" @click="showSecurity">SECURITY</a>
        </div>
        <div class="item" style="padding-top:15px;">
          <a class="menuItem" href="/privacy" @click="showPrivacy">PRIVACY</a>
        </div>
        <div class="item" style="padding-top:15px;">
          <a class="menuItem" href="https://guides.spext.co/" target="_blank" rel="noopener" @click="showGuide">GUIDES</a>
        </div>
        <!-- <div class="item" style="padding-top:15px;">
          <a class="menuItem" href="https://legal.spext.co/" target="_blank" rel="noopener" @click="showLegal">FOR LEGAL</a>
        </div> -->
        <div class="item" style="padding-top:15px;">
          <a class="menuItem" href="https://blog.spext.co/" target="_blank" rel="noopener" @click="showBlog">BLOG</a>
        </div>
      </div>
    </div>

    <div class="container grid ui stackable" style="margin-top:auto;padding-top:2rem;">
      <div class="sixteen wide middle aligned column" style="margin-top:2%;">
        <div class="ui row">
          <div>
            <h1 class="ui p1 header" style="text-align:center;margin-bottom:10px;">
               SECURITY
            </h1>
          </div>
          <div style="text-align:center;">
            <div style="font-weight:normal;color:#515151;font-size:14px;">Date of Last Revision: August 10th, 2021</div>
          </div>
        </div>
      </div>
    </div>

    <div class="ui column">
      <img class="ui centered image" src="../assets/images/security.png">
    </div>

    <div class="container grid ui stackable" style="margin-top:auto;padding-top:2rem;">
      <div class="fourteen wide middle aligned centered column" style="margin-top:2%;">
        <div class="ui row">
          <p>
            We consider ourselves as stewards of your data and take this responsibility very seriously. We have multiple layers of security to ensure your privacy. We do not sell, rent or share your information with third parties for promotional use.
          </p>
          <h5>Access Control</h5>
          <p>You will always have access to your files. Should you end your subscription you will lose other functionality like editing but you will always be able to view, export, and download. You can also delete the information if you don't want to store at anytime. When you remove it, we delete the audio, video and the transcription from our platform completely.</p>
          <p>Employees don't have access to your audio and transcripts unless you give us permission. Our privacy page lists this out in further detail.</p>
          <h5>Data Encryption</h5>
          <p>We encrypt all data that goes between you and Spext’s servers using field-standard TLS (Transport Layer Security). Your data is encrypted when transferred between data centers for backup and replication.</p>
          <h5>Network protection</h5>
          <p>Spext has multiple layers of security including firewalls, intrusion protection systems and network segregation.</p>
          <h5>Secure data centers</h5>
          <p>Our servers are located within enterprise-grade hosting facilities that have robust physical security controls to prevent physical access. These controls include 24/7/365 monitoring and surveillance, on-site security staff and regular ongoing security audits.</p>
          <h5>Security monitoring</h5>
          <p>
            Security team continuously monitor security systems, event logs, notifications and alerts from all systems to identify and manage threats.
          </p>
        </div>
      </div>
    </div>

<div style="margin:10em;">
  &nbsp;
</div>

  </div>
</template>

<script>
require('@fortawesome/fontawesome-pro/css/all.css')
export default {
  components: {
    login: () => import('@/components/auth/Auth')
  },
  data () {
    return {
      menu: false
    }
  },
  mounted () {
    delete localStorage['retryCount']
  },
  methods: {
    showPricing () {
      // $('#pricing').modal('show')
      $.scrollTo(document.getElementById('pricing'), 800)
    },
    showBlog () {

    },
    showFAQ () {

    },
    showSecurity () {

    },
    showHome () {

    },
    showPrivacy () {

    },
    showGuide () {

    },
    showLegal () {

    },
    showLogin () {

    }
  }
}

</script>

<style lang="less" scoped>
 @media screen and (max-width: 1024px) {
    .hide {
        display: none !important;
    }
}
@media screen and (min-width: 0px) and (max-width: 1024px) {
    .show {
        display: flex;
    }
    .show1{
      display: block;
    }
    /* show it on small screens */
}
@media screen and (min-width: 1025px) and (max-width: 2048px) {
    .show {
        display: none;
    }
    .show1{
      display: none;
    }
    /* hide it elsewhere */
}
.round {
  border-radius: 6px !important;
}

.button {
  font-size: 13px !important;
  font-weight: bold;
}

.tealText {
  color: #5A29A3 !important;
}

.bottomMargin {
  margin-bottom: 0 !important;
}

.ui.menu .item:before {
  background: white;
}

.menuHeight {
  height: 5vh !important;
}

@media only screen and (max-width: 767px) {
  .menuHeight {
    height: initial !important;
  }
}

h1.ui.header {

  /* font-size: 35px; */
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #c7c7c7;
  margin-bottom: 5px;
  margin-top: 30px;
}

.menuItem{
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 15px;
  text-align: center;
  color: #515151;
  cursor: pointer;
}
h1.p1.header{

font-style: normal;
font-weight: bold;
line-height: normal;
font-size: 30px;
text-align: center;
text-transform: uppercase;

color: #C75146;
}
.p1.subheader{

font-style: normal;
font-weight: normal;
line-height: normal;
font-size: 18px;
text-align: center;

color: #515151;
}
.bolder{
  font-weight: 500;
}
h5{
  margin-bottom: 0;

font-style: normal;
font-weight: bold;
line-height: normal;
font-size: 20px;

color: #515151;
}
p{

font-style: normal;
font-weight: normal;
line-height: normal;
font-size: 20px;

color: #515151;
}
a.ui.item:hover{
  background: transparent;
}
// debug
// :not(path):not(g) {
//   color:                    hsla(210, 100%, 100%, 0.9) !important;
//   background:               hsla(210, 100%,  50%, 0.5) !important;
//   outline:    solid 0.25rem hsla(210, 100%, 100%, 0.5) !important;
//   box-shadow: none !important;
// }
</style>
